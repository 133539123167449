import { ref } from '@vue/composition-api'

import { pushPopup } from '@/utils/pushPopup'
import {
  folders, files, popupsArr1, popupsArr2, popupsArr3,
} from './arrs'

export default {
  name: 'step3',
  setup(props, ctx) {
    // const { $router } = ctx.root
    const { emit } = ctx
    const active = ref(true)
    const popups = ref([])
    const wrapperMode = ref(false)
    const step = ref(0)
    const iPopup = ref(0)
    const lengthDisabledPopup = ref(0)

    const init = () => {
      pushPopup(popupsArr1, popups.value, iPopup.value)
    }

    init()

    const nextStep = () => {
      if (step.value === 5) {
        // $router.push('/lesson2')
        emit('click', 4)
        return
      }
      step.value += 1
      if (step.value === 3) {
        lengthDisabledPopup.value = 6
      }
    }
    const consoleFinish = () => {
      step.value += 1
      if (step.value === 2) {
        lengthDisabledPopup.value = 4
        pushPopup(popupsArr2, popups.value, iPopup.value)
      }
    }

    const loadingFinish = () => {
      step.value += 1
      pushPopup(popupsArr3, popups.value, iPopup.value)
    }

    return {
      active,
      popups,
      wrapperMode,
      step,
      folders,
      files,
      lengthDisabledPopup,
      nextStep,
      consoleFinish,
      loadingFinish,
    }
  },
}
