export const folders = [
  { title: 'Домашняя' },
  { title: 'Приложения' },
  { title: 'Рабочие файлы' },
  { title: 'Графический драйвер' },
  { title: 'Виджеты' },
  { title: 'Документы' },
  { title: 'Удаленные' },
  { title: 'Исполняемые файлы' },
  { title: 'Библиотека' },
]

export const files = [
  { title: 'Медиа10' },
  { title: 'Драйвер Расширения' },
  { title: 'Язык' },
  { title: 'Веб-Сервер' },
  { title: 'Графический драйвер' },
  { title: 'Виджет' },
]

export const popupsArr1 = [
  { text: 'Ух, как много папок! Обычно вирусы маскируются под исполняемые файлы.' },
  { text: 'Исполняемый файл — это файл, который внутри себя содержит программу, которую может выполнить компьютер.' },
  { text: 'Например, когда ты пытаешься установить на компьютер игру, первым делом запускаешь тот самый исполняемый файл.' },
  { text: 'Такие файлы хранятся в папке «Исполняемые файлы».', buttonTitle: 'Открыть директорию «Исполняемые файлы»' },
]

export const popupsArr2 = [
  { text: 'Теперь самое интересное! Как понять, какой исполняемый файл является вирусом?' },
  { text: 'Для этого нам нужно проанализировать код оставшихся файлов с помощью антивируса.', buttonTitle: 'Запустить антивирус' },
]

export const popupsArr3 = [
  { text: 'Подозрительный файл найден! Отправлю его на исследование в «Лабораторию Касперского».', buttonTitle: 'ПРОДОЛЖИТЬ' },
]
