import { ref } from '@vue/composition-api'

import { pushPopup } from '@/utils/pushPopup'

const arr = [
  { text: 'В компьютере существует много файлов разного типа: видео, изображения, музыка, документы, программы и другие. Под каким-то из них может скрываться вирус.' },
  { text: 'Все эти файлы распределены по директориям (это такие «папки», созданные системой для хранения файлов).' },
  { text: 'Давайте посмотрим на них.', buttonTitle: 'Показать все директории' },
]

export default {
  name: 'step2',
  setup() {
    const active = ref(true)
    const popups = ref([])
    const container = ref(false)

    const automatic = ref(false)

    const init = () => {
      pushPopup(arr, popups.value)
    }

    init()

    const consoleFinish = () => {
      setTimeout(() => {
        active.value = false
        container.value = true
      }, 1000)
    }

    const nextStep = () => {
      automatic.value = true
      setTimeout(() => {
        consoleFinish()
      }, 1000)
    }

    return {
      active,
      popups,
      container,
      automatic,
      consoleFinish,
      nextStep,
    }
  },
}
